// src/components/page_home.js
import React, { useState } from 'react';
import LoadingOverlay from './loading_overlay';

const Page_Home = () => {
	const [loading, setLoading] = useState(false);

	const button_overlay = () => {
		setLoading(true);

		// Overlay für 3 Sekunden anzeigen
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	};

	return (
		<div>
			<h1>Home Page</h1>
			<p>Welcome to my SPA!</p>

			{/* Temporärer Button zum Testen */}
			<button onClick={button_overlay}>Show Loading Overlay</button>

			{/* Das Overlay wird nur angezeigt, wenn "loading" true ist */}
			{loading && <LoadingOverlay />}
		</div>
	);
};

export default Page_Home;
