import React from 'react';

function Users() {
	return (
		<div>
			<h1>Users Seite</h1>
		</div>
	);
}

export default Users;
