import React, { useState, useEffect } from 'react';
import LoadingOverlay from './loading_overlay';

export default () => {
	// Hier wird zunächst ein Lade Spinner angezeigt, während die Daten vom Server geladen werden
	// Nachdem die Daten geladen wurden, wird die Tabelle angezeigt

	const [loading, setLoading] = useState(true);
	const [leaderboard, setLeaderboard] = useState([]);

	const get_leaderboard = (code) => {
		setLoading(true);
		fetch('https://api.jinx.events', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mission: 'jinx_get_leaderboard', code: code }),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data.success && data.leaderboard) {
					setLeaderboard(data.leaderboard);
				} else {
					console.log(data.error);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error:', error);
				setLoading(false);
			});
	};

	useEffect(() => {
		console.log('Leaderboard');
		get_leaderboard(localStorage.getItem('code'));
	}, []);

	return (
		<>
			{leaderboard.length > 0 && (
				<div className="box">
					<h1>Leaderboard</h1>
					<table className="leaderboard">
						<tr>
							<th>Nr</th>
							<th>Name</th>
							<th>Score</th>
						</tr>
						{leaderboard.map((row, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>{row.username}</td>
								<td>{row.score}</td>
							</tr>
						))}
					</table>
				</div>
			)}

			{loading && <LoadingOverlay scope="overlay_leaderboard" />}
		</>
	);
};
