// src/js/sw_registration.js

export default (sw_path) => {
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', () => {
			navigator.serviceWorker
				.register(sw_path)
				.then((registration) => {
					registration.onupdatefound = () => {
						const installingWorker = registration.installing;
						installingWorker.onstatechange = () => {
							if (installingWorker.state === 'installed') {
								if (navigator.serviceWorker.controller) {
									// Eine neue Version ist verfügbar
									console.log(
										'Neue Inhalte sind verfügbar; bitte Seite neu laden.'
									);
									if (
										window.confirm(
											'Es gibt eine neue Version der App. Möchtest du aktualisieren?'
										)
									) {
										window.location.reload();
									}
								}
							}
						};
					};
				})
				.catch((error) => {
					console.error('Service Worker Registrierung fehlgeschlagen:', error);
				});
		});
	}
};
