// src/components/login.js
import React, { useState, useEffect } from 'react';
import LoadingOverlay from './loading_overlay';

export default ({ login_success }) => {
	const code = localStorage.getItem('code');

	const [loading, setLoading] = useState(true);
	const [loginmask, setLoginmask] = useState(false);
	const [error, setError] = useState(false);

	const button_login = () => {
		setLoading(true);
		const input_code = document.getElementById('code').value;
		if (input_code) {
			localStorage.setItem('code', input_code);
			const code = input_code;
			setError(false);
		} else {
			setLoading(false);
			setLoginmask(true);
			setError('Enter Code');
		}
		// window.location.reload();
	};

	const login = (code) => {
		setLoading(true);
		fetch('https://api.jinx.events', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mission: 'login', code: code }),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data.success) {
					login_success();
				} else {
					localStorage.removeItem('code');
					setLoading(false);
					setLoginmask(true);
					if (data.error) {
						console.log(data.error);
						setError(data.error);
					}
				}
			})
			.catch((error) => {
				console.error('Error:', error);
				setLoading(false);
				setLoginmask(true);
				setError('Network Error');
			});
	};

	useEffect(() => {
		console.log('Login');
		if (code) {
			console.log('Login with Code: ' + code);
			login(code);
		} else {
			setLoading(false);
			setLoginmask(true);
		}
	}, [code, login_success]);

	return (
		<>
			{loginmask && (
				<>
					<div className={`box_login ${error ? 'error_login' : ''}`}>
						<input type="text" id="code" placeholder="Code" />
						<button
							onClick={() => {
								button_login();
							}}
						>
							Login
						</button>
					</div>
					{error && <p className="error_login">{error}</p>}
				</>
			)}
			{loading && <LoadingOverlay />}
		</>
	);
};
