import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function Admin() {
	return (
		<div>
			<h1>Admin Seite</h1>
			<ul>
				<li>
					<Link to="users">Users</Link>
				</li>
				<li>
					<Link to="quests">Quests</Link>
				</li>
			</ul>
			<Outlet /> {/* Hier werden die verschachtelten Routen gerendert */}
		</div>
	);
}

export default Admin;
