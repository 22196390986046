// app.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './app.css';
import Admin from './admin';
import Users from './admin/users';
import Quests from './admin/quests';
import Nav from './js/nav';
import Page_Login from './components/page_login';
import Page_Home from './components/page_home';
import Page_Leaderboard from './components/page_leaderboard';
import Page_User from './components/page_user';

function App() {
	const [LoggedIn, setLoggedIn] = React.useState(false);

	return (
		<Router>
			{LoggedIn ? (
				<>
					<Nav />
					<Routes>
						<Route path="/" element={<Page_Home />} />
						<Route path="/leaderboard" element={<Page_Leaderboard />} />
						<Route path="/user" element={<Page_User />} />
						<Route path="/admin" element={<Admin />} />
						<Route path="/admin/users" element={<Users />} />
						<Route path="/admin/quests" element={<Quests />} />
					</Routes>
				</>
			) : (
				<Page_Login login_success={() => setLoggedIn(true)} />
			)}
		</Router>
	);
}

export default App;
