// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import sw_registration from './js/sw_registration';

sw_registration(process.env.PUBLIC_URL + '/sw.js');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
