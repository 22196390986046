import React from 'react';

export default () => {
	return (
		<div>
			<h1>User</h1>
			<p>Waddup</p>
			<button
				onClick={() => {
					localStorage.removeItem('code');
					// window.location.reload();
					window.location.href = '/';
				}}
			>
				Logout
			</button>
		</div>
	);
};
